/* Declarar la variable en :root */
:root {
  --color-primario: #061C40;
  --color-secundario: #73879C;
}

.nav-sm .nav.child_menu li.active, .nav-sm .nav.side-menu li.active-sm {
  border-right: 5px solid #061C40;
}

.nav.side-menu> li.current-page, .nav.side-menu> li.active {
border-right: 5px solid #061C40;
}

body {
  color: #73879C;
  /* background: #053C5A; */
  /* background: #2D4A7B; */
  background: var(--color-primario) ;
  /*#ECF0F1; #FCFCFC*/
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.471;
  width: 100%;
  height: 100%;
}

.link-logo-fs .link-logo-fp{
display: inline-block;
}

.img_logo_fs {
margin-left: 8px !important;
width: 105px !important;
margin-top: 5px !important;
}

.img_logo_fp {
margin-top: 5px !important;
left: 190px;
width: 105px !important;
}

.img_logo{
width: 130px !important;
margin-top: 5px !important;
}

.logo_fp {
margin-top: 5px !important;
width: 130px !important;
}

.logo-login{
width: 300px !important;
height: 130px !important;
margin-top: 5px !important;
margin-bottom: 10px !important;
} 


table {
text-align: left;
}

html {
width: 100%;
height: 100%;
overflow-x: hidden;
}

.nav-sm .nav.side-menu {
overflow-y: scroll !important;
}

.nav.side-menu> li.active > a {
  text-shadow: rgba(0, 0, 0, 0.25) 0 -1px 0;
  /* background: -webkit-linear-gradient(#115173, #115173), #2A3F54;
  background: -moz-linear-gradient(#115173, #115173), #2A3F54;
  background: -o-linear-gradient(#115173, #115173), #2A3F54;
  background: linear-gradient(#115173, #115173), #2A3F54; */

  background: -webkit-linear-gradient(var(--color-primario),var(--color-primario));
  background: -moz-linear-gradient(var(--color-primario),var(--color-primario));
  background: -o-linear-gradient(var(--color-primario),var(--color-primario));
  background: linear-gradient(var(--color-primario),var(--color-primario));
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
}

.nav-md ul.nav.child_menu li:before {
background: #8C8C8C;
bottom: auto;
content: "";
height: 8px;
left: 23px;
margin-top: 15px;
position: absolute;
right: auto;
width: 8px;
z-index: 1;
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
border-radius: 50%;
}

/**
*
* Titulo logo de Flowsoft
*
*/
.nav_title {
width: 230px;
float: left;
background: #fff;
border-radius: 0;
height: 57px;
}

.nav_title a {
padding-left: 0px;
height: 100%;
text-align: center;
}

.nav-md .nav_title a img {
height: 55px;
vertical-align: top;
}

.nav-md .menu_nav {
margin-left: 18%;
}

.nav-md li.clientes_sadmin {
display: inline;
}

.nav-sm .nav_title a img {
height: 100%;
vertical-align: top;
}

.nav-sm .menu_nav {
margin-left: 5%;
}

.nav-sm li.clientes_sadmin {
display: none;
}

/**
*
* Custom tables
*
*/

.table-scroll {
overflow: hidden;
}

/**
*
* Custom datatables
*
*/

.dataTables_wrapper .row {
margin-right: 0px;
margin-left: 0px;
}

/* Hace que todo quede en una sola linea sin saltos de linea */
.datatable-server-scroll th,
.datatable-server-scroll td,
.datatable-simple-scroll th,
.datatable-simple-scroll td {
white-space: nowrap;
}

/* Soluciona el error de que desaparece el scroll x */
.dataTables_wrapper > .dataTables_scroll > div.dataTables_scrollBody,
.dataTables_wrapper > .dataTables_scroll > div.dataTables_scrollHead,
.dataTables_wrapper > .dataTables_scroll > div.dataTables_scrollFoot {
width: 100% !important;
}
.dataTables_wrapper > .dataTables_scroll > div.dataTables_scrollFoot > div.dataTables_scrollFootInner > table.table-striped{
/* width: 0px !important; */
margin-left: 0px
}
.datatable-fixer .dataTables_scrollBody {
overflow-x: auto !important;
overflow-y: hidden !important;
}

.dataTables_filter {
display: none;
}

.contenedor-accion-tabla-fixer {
height: 25px;
}

.boton-tablas-raras {
  margin: 0px;
  padding: 3px 10px;
}

.inline {
display: inline;
}

.btn-sim-link {
color: #fff;
/*padding-top: 8px;*/
}

.scrollable {
max-height: 400px;
overflow-y: scroll;
overflow-x: scroll;
}

.scrollable th, td {
white-space: nowrap;
}

/* .grafico-buffer {
max-height: 500px;
max-width: 680px;
} */

#chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }

.filtro_multiple .select2-container--default .select2-selection--multiple .select2-selection__choice {
color: black;
}

table.dataTable tbody tr.selected {
  background-color: #aab7d1;
  color: white;
}

#collapseColumnSelect > .select2-container {
  width: 100% !important;
}

/**
* Menu fixer, sin esto, las opciones del menu, al ser muchas, no se podrian ver
*/

.menu-fixer .profile {
height: 60px;
}

.menu-fixer .main_menu_side, .menu-fixer .menu_section {
height: 100%;
}

.menu-fixer .nav.side-menu {
overflow-y: auto;
overflow-x: hidden;
height: 500px;
}

body.nav-sm .menu-fixer .nav.side-menu {
overflow-y: hidden;
overflow-x: hidden;
}

body.nav-sm .menu-fixer .nav.side-menu li.active-sm{
overflow-y: hidden;
overflow-x: hidden;
}

.menu-fixer .nav.side-menu::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: #053C5A;
}

.menu-fixer .nav.side-menu::-webkit-scrollbar
{
width: 10px;
background-color: #053C5A;
}

.menu-fixer .nav.side-menu::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: #F5F5F5;
}

.filtro-avanzado-contenedor .select2-container {
width: 100% !important;
}

.filtro-avanzado-contenedor .select2-selection--multiple {
min-height: 34px !important;
}

.input-modificador input {
width: 200px !important;
margin-top: 0px;
}

.notificaciones-dropdown {
width: 350px !important;
max-height: 242px;
overflow-y: auto;
}

.notificaciones-dropdown .list-item {
border-bottom: 1px solid #E6E9ED;
}

.notificaciones-dropdown a {
display: flex !important;
flex-direction: row;
align-items: center;
justify-content: space-between;
}

.notificaciones-dropdown a.notificacion-no-leida {
background-color: #F8F8F8;
}

.notificaciones-dropdown a:hover {
background-color: #E8E8E8 !important;
}

.notificaciones-dropdown > * {
height: 60px;
}

.notificaciones-dropdown .texto {
display: flex;
flex-direction: column;
flex-basis: 177px;
}

.icono-derecho-escondido {
font-size: 0px !important;
}

.perfiles-porcentajes-fixer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/**
* Para centrar el datatable
*/
.center_align {
text-align: center;
}

.left_align {
text-align: left !important;
}

tfoot input {
width: 100% !important;
}

tbody tr td {
padding: 0px !important;
padding-left: 4px !important;
padding-right: 4px !important;
padding-top: 2px !important;
padding-bottom: 2px !important;
}

.campo_edicion {
width: 60px !important;
}

.notificacion_expulsion_n {
position: absolute;
width:100%;
height:100%;
background-color: rgba(255,255,255,0.9);
z-index: 1100;
display: flex;
justify-content: center;
}

.container_notificacion_expulsion {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.content_notificacion_expulsion {
  text-align: center;
  display: inline-block;
}

.title_notificacion_expulsion {
  font-size: 40px;
  margin-bottom: 40px;
  margin-top: 10%;
}

.title_notificacion_expulsion small {
font-size: 12px;
}

/**
* Para loader de paginas
*/

.fullscreen_loader_n {
position: absolute;
width:100%;
height:100%;
background-color: rgba(255,255,255,0.9);
z-index: 1100;
display: flex;
justify-content: center;
}

.container_fullscreen_loader {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.content_fullscreen_loader {
  text-align: center;
  display: inline-block;
}

.title_fullscreen_loader {
  font-size: 40px;
  margin-bottom: 40px;
  margin-top: 10%;
}

.title_fullscreen_loader small {
font-size: 12px;
}

/**
* Fin de loader de paginas
*/

.nav-md .titulo-nav {
  position: absolute;
  margin-left: 23%;
  margin-top: 0.7%;
  font-size: 18px;
}

.nav-sm .titulo-nav {
  position: absolute;
  margin-left: 10%;
  margin-top: 0.7%;
  font-size: 18px;
}

.ordenes_sug > li > a {
padding: 5px 15px !important;
}

.select2-selection {
/*min-height: 21px !important; */
/*height: 21px !important; */
}

.nav_menu {
height: 40px !important;
margin-bottom: 0px !important;
}

.toggle {
padding-top: 8px !important;
font-size: 8pt;
}

.navbar-brand, .navbar-nav>li>a {
line-height: 15px !important;
}

.nav_title {
  /*width: 107% !important;*/
  height: 19px !important;
}

.menu-fixer .nav.side-menu {
  z-index: 1800 !important;
}

.profile_info {
padding: 22px 10px 0px !important;
}

.navbar {
  min-height: 40px !important;
}

.nav-md .nav_title a img {
  height: auto !important;
  width: 80px;
}

/* Margen de cada panel-x que hay en cada vista.*/
.nav-sm .container.body .right_col {
  padding: 0px 0px 0px !important;
}

.nav-md .container.body .right_col {
padding: 0px 0px 0 !important;
}

.x_title {
   margin-bottom: 0px !important;
}

.x_panel {
padding-right: 1px !important;
padding-left: 1px !important;
padding: 5px 10px !important;
}

.x_content {
  margin-top: 0px !important;
}
.tab-pane {
padding-top: 5px;
}

.form-group {
    margin-bottom: 0px !important;
}

.nav-md .container.body .col-md-3.left_col.menu_fixed {
z-index: 888;
}

.nav-tabs.ordenes_sug > li {
  width: 230px !important;
}

/*.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0px !important;
}*/

.sug_aprob {
line-height: 1;
padding: 1px 0px 0px 0px;
font-size: 12px;
}

.select2_min > span {
/*z-index: 9999 !important;*/
min-height: 24px !important;
/*height: 26px !important;*/
overflow-y: hidden !important;
padding-top: 0px !important;
}

.select2_min .select2-container--default .select2-selection--single .select2-selection__rendered {
padding-top: 0px !important;
}

.select2_min .select2-container--default .select2-selection--single .select2-selection__arrow {
   height: 27px !important;
}

.select2_min .select2-container--default .select2-selection--single {
 height: 27px !important;
 min-height: 27px !important;
}

.table > thead:first-child > tr:first-child > th {
  font-size: 13px !important;
}

.table > tbody > tr > td {
font-size: 12px !important;
}

.datepicker {
z-index: 2000 !important;
}

.btn-primary-edited {
background-color: #ced9df !important;
z-index: 9999;
border: 1px solid #c7cacc;
}

.active-edited {
background-color: #337ab7 !important;
color: white !important;
z-index: 9999;
}

.resizable_div {
padding-bottom: 2%;
}

.resizable_div > .ui-resizable-s {
z-index: 99999;
background: rgba(5,60,90,0.42);
height: 5px;
margin-bottom: 10px;
cursor: ns-resize;
}
#boton-atras {
position:relative;
left: 20%;
}

#boton-adelante {
position:absolute;
left: 75%;
}
.des_filtros{
background-color:white;
border-style: solid;
border-color:rgb(230, 233, 237);
}
.contenedor_filtros{
margin-left: 25px;
}
#btn-reporte-consumo{
position:  relative;
left: 39%;
margin-top: 2%;
}

#div-filtro-bodega{
border-radius: 3px;
border-style: solid;
position: relative;
left: 20%;
margin-top: 10%;
background-color: #ffffffff;
width: 60%;
}

.btn_guardar_max_bodega{
position: relative;
top: 4px;
right: 300%;
}


.contenedor-zonas {
position: relative;
  left: 24.9%;
}
.fc-time-grid .fc-event, .fc-time-grid .fc-bgevent {
opacity: 1;
}

#redirect-modificacion-masiva{
position: relative;
left: 50%;
}
.ui-widget-content {
background: #000000;

}

#form_perfil_crud{
height:830px;
}

@media (min-width: 992px) {
  .modal-lg {
      width:95%
  }
}

@media (min-width: 992px) {
  .modal-lg {
      width:95%
  }
}

.estado-label {
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff; /* Texto blanco para contraste */
  font-weight: bold;
}

.estado-revisado {
  background-color: #28a745; /* Verde aprobado */
  margin-left: 10px;
}

.estado-pendiente {
  background-color: #dc3545; /* Rojo pendiente */
  margin-left: 10px;
}


/* #btn_submit_crear{
position: absolute;
top:780px;
left:350px;
} */

/* #btn_cancelar_perfil{
  position: absolute;
  top:780px;
  left:450px;
} */


/* button, a {
  background-color: var(--color-primario) !important;
} */

.btn-primary {
  background-color: var(--color-primario) !important;
}
